<template>
  <div class="text-center border p-3">
    <b-img thumbnail fluid :src="path"></b-img>
    <div class="pt-3">
      <p>{{ filename }}</p>
      <b-button variant="danger" size="sm">Delete</b-button>
      <b-button variant="info" size="sm" class="ml-3">Edit</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditImage",

  props: {
    path: String,
    filename: String
  },
  
  methods: {
  }
};
</script>

<style lang="scss" scoped>
</style>
