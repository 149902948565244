<template>
  <div class="admin">
    <b-container fluid v-if="!isModalVisible" class="pt-4 px-5">
      <b-row>
        <b-col>
          <h1 class="title text-center">Website Admin Console</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="section-title">New Pool Images</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="poolImage in poolImages" :key="poolImage.id" md="2" class="py-3">
          <EditImage
            :path="poolImage.path"
            :filename="poolImage.name"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="section-title">Service Images</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="serviceImage in serviceImages" :key="serviceImage.id" md="2" class="py-3">
          <EditImage
            :path="serviceImage.path"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="section-title">Upload Images</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png, .gif"
            size="md"
          >
          </b-form-file>
          <div class="pt-3">
            <div>Selected file: {{ file ? file.name : '' }}</div>
            <div class="mt-3">
              <span class="mr-3"><b-button variant="danger" @click="file=undefined">Reset</b-button></span>
              <b-button variant="primary" @click="uploadFile" :disabled="file==null">Upload Image</b-button>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <b-form-group label="Image Type">
            <b-form-radio-group id="radio-group" v-model="selected" name="radio-sub-component">
              <b-form-radio value="new">New Project</b-form-radio>
              <b-form-radio value="service">Service</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    
    <Modal v-if="isModalVisible" @close="closeModal">
      <template v-slot:body>
        <div class="text-center">
          <span class="modal-check"><i class="far fa-check-circle"></i></span>
          <p class="modal-text ml-2">{{ modalMessage }}</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { HTTP } from "../http-common";
import Modal from "@/components/Modal.vue";
import EditImage from "@/components/EditImage.vue";

export default {
  name: "Admin",

  components: {
    Modal,
    EditImage
  },

  data() {
    return {
      poolImages: [],
      serviceImages: [],
      isModalVisible: false,
      modalMessage: "You'session has expired. Please log in again.",
      file: null,
      selected: 'new'
    };
  },

  mounted: function() {
    this.$nextTick(function() {
      HTTP.get("pools/images/new").then(response => {
        var payload = response.data;
        this.poolImages = payload;
      });

      HTTP.get("pools/images/service").then(response => {
        var payload = response.data;
        this.serviceImages = payload;
      });
    });
  },
  
  methods: {
    closeModal() {
      this.$router.push({name:'Home'});
    },
  
    uploadFile: async function(evt) {
      if( this.file == "") {
        alert("Please choose an image to upload first.");
        return;
      }
      evt.preventDefault()
      
      let formData = new FormData();
      let imagefile = this.file;
      formData.append("image", imagefile);
      await HTTP.post("pools/images/new/upload", formData, {'Content-Type': 'multipart/form-data'}).then(res => {
        let response = res;
        console.log(response);

      });
    }

    // uploadFile() {
    //   let formData = new FormData();
    //   let imagefile = this.file;
    //   formData.append("image", imagefile);
    //   HTTP.post("pools/images/new/upload", formData, {'Content-Type': 'multipart/form-data'}).then(res => {
    //     let response = res;
    //     console.log(response);

    //   });
    // }
  },

  created() {
    if (!this.$cookies.isKey("ab_pools")) {
      this.$store.commit('setSession');
      this.isModalVisible = true;

    } 
  }
};
</script>

<style scoped lang="scss">
.admin {
  font-family: "Roboto", sans-serif;
}

.title {
  font-size: $base-font-size * 1.3;  
}

.section-title {
  font-size: $base-font-size * 1; 
  padding-bottom: .4em; 
}
</style>
